import React, {FC, useEffect, useState} from 'react';

import classes from './NewsFeed.module.scss';
import pencilSvg from '../../assets/svgs/pencil.svg';
import buildingsSvg from '../../assets/svgs/buildings-dark.svg';
import briefcaseSvg from '../../assets/svgs/briefcase.svg';
import chartSvg from '../../assets/svgs/chart-line-dark.svg';
import chainSvg from '../../assets/svgs/chain.svg';
import dollarSignSvg from '../../assets/svgs/dollar-sign-dark.svg';
import {Table} from 'react-bootstrap';
import Utils from '../../utils/utils';
import CryptoChainIcon from '../Misc/CryptoChainIcon/CryptoChainIcon';
import axios from 'axios';

const NewsFeed: FC = (props) => {
  const [newsFeedData, setNewsFeedData] = useState<any>([]);

  useEffect(() => {
    const fetchNewsArticles = async () => {
      const companies = await axios
        .get(`https://api.propertycoins.io/api/v1/newsArticles`)
        .then((res) => {
          setNewsFeedData(res.data.data);
        });
    };

    fetchNewsArticles().catch((err) => {
      console.log(err);
    });
  }, []);

  const handleLinkClicked = (url: string) => {
    window.open(url, '_blank');
  };

  const tableItems = newsFeedData.map((newsItem: any, index: number) => {
    return (
      <tr key={index} className={classes.TableRow}>
        <td>
          <img src={newsItem.icon} height={15} />
        </td>
        <td>
          <div className={classes.ArticleTimestamp}>
            {new Date(newsItem.timestamp).toLocaleDateString()}
          </div>
        </td>
        <td>
          <a
            className={classes.ArticleTitle}
            onClick={() => handleLinkClicked(newsItem.url)}
          >
            {newsItem.title}
          </a>
        </td>
        <td>
          <div className={classes.ArticleSource}>{newsItem.source}</div>
        </td>
      </tr>
    );
  });
  return (
    <div className={classes.NewsFeed}>
      <Table className={classes.Table} responsive>
        <tbody>{tableItems}</tbody>
      </Table>
    </div>
  );
};

export default NewsFeed;
