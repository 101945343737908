import React, {FC} from 'react';

import classes from './CompaniesList.module.scss';
import {Badge, Table} from 'react-bootstrap';
import CryptoChainIcon from '../Misc/CryptoChainIcon/CryptoChainIcon';
import AssetTypeBadge from '../Misc/AssetTypeBadge/AssetTypeBadge';
import Utils from '../../utils/utils';
import pencilSvg from '../../assets/svgs/pencil.svg';
import buildingsSvg from '../../assets/svgs/buildings-dark.svg';
import briefcaseSvg from '../../assets/svgs/briefcase.svg';
import chartSvg from '../../assets/svgs/chart-line-dark.svg';
import chainSvg from '../../assets/svgs/chain.svg';
import dollarSignSvg from '../../assets/svgs/dollar-sign-dark.svg';

interface CompaniesListProps {
  companies: any[];
}

const CompaniesList: FC<CompaniesListProps> = (props) => {
  const companyTableItems = props.companies.map((company, index) => {
    return (
      <tr key={index} className={classes.TableRow}>
        <td className={classes.Rank}>{index + 1}</td>
        <td>
          <a
            className={classes.CompanyName}
            href={company.websiteUrl}
            target={'_blank'}
          >
            {company.name}
          </a>
        </td>
        <td>{company.propertiesCount > 0 ? company.propertiesCount : ''}</td>
        <td>{Utils.capitalizeFirstLetter(company.assetType)}</td>
        <td>
          {company.totalValueLocked > 0 &&
            Utils.moneyFormat(company.totalValueLocked, 0)}
        </td>
        <td>
          {company.chains.map((chainIdentifier: string, index: number) => {
            return (
              <span key={index}>
                <CryptoChainIcon identifier={chainIdentifier} size={23} />
              </span>
            );
          })}
        </td>
        <td>
          {company.minimumBuy !== undefined &&
            Utils.moneyFormat(company.minimumBuy, 0)}
        </td>
        <td>
          {company.averageYield !== undefined &&
            Utils.percentFormat(company.averageYield, false)}
        </td>
      </tr>
    );
  });

  return (
    <div className={classes.CompaniesList}>
      <Table className={classes.Table} hover responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <img className={classes.HeaderIcon} src={pencilSvg} height={25} />
              Name
            </th>
            <th>
              <img
                className={classes.HeaderIcon}
                src={buildingsSvg}
                height={25}
              />
              Properties
            </th>
            <th>
              <img
                className={classes.HeaderIcon}
                src={briefcaseSvg}
                height={25}
              />
              Asset Types
            </th>
            <th>
              <img className={classes.HeaderIcon} src={chartSvg} height={25} />
              TVL
            </th>
            <th>
              <img className={classes.HeaderIcon} src={chainSvg} height={25} />
              Chain
            </th>
            <th>
              <img
                className={classes.HeaderIcon}
                src={dollarSignSvg}
                height={25}
              />
              Min Buy
            </th>
            <th>% Yield</th>
          </tr>
        </thead>
        <tbody>{companyTableItems}</tbody>
      </Table>
    </div>
  );
};

export default CompaniesList;
