import React, {FC} from 'react';

import classes from './AggregateBox.module.scss';

interface AggregateBoxProps {
  title: string;
  value: any;
  icon: any;
}

const AggregateBox: FC<AggregateBoxProps> = (props) => {
  return (
    <div className={classes.AggregateBox}>
      <div className={classes.Title}>
        {props.title}{' '}
        <span>
          <img src={props.icon} height={38} />
        </span>
      </div>
      <div className={classes.Value}>{props.value}</div>
    </div>
  );
};

export default AggregateBox;
