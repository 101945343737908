import React, {FC} from 'react';

import classes from './Footer.module.scss';

const Footer: FC = (props) => {
  return (
    <div className={classes.Footer}>
      &copy; Frexa, LLC {new Date().getFullYear()}
    </div>
  );
};

export default Footer;
