const Utils = {
  getApiServerUrl: () => {
    const nodeEnv = process.env.NODE_ENV;

    if (nodeEnv === 'development') {
      return '';
    } else if (nodeEnv === 'production') {
      return '';
    }
  },
  moneyFormat: (amount: number, maximumFractionDigits?: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: maximumFractionDigits ? maximumFractionDigits : 0,
    });

    return formatter.format(amount);
  },
  capitalizeFirstLetter: (text: string) => {
    return text.substr(0, 1).toUpperCase() + text.substr(1, text.length);
  },
  percentFormat: (value: number, isDecimal: boolean) => {
    return `${isDecimal ? value * 100 : value}%`;
  },
};

export default Utils;
