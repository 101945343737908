const tickerToNameMap: {[ticker: string]: string | undefined} = {
  $pac: undefined,
  '0xbtc': undefined,
  '2give': undefined,
  aave: undefined,
  abt: undefined,
  act: undefined,
  actn: undefined,
  ada: undefined,
  add: undefined,
  adx: undefined,
  ae: undefined,
  aeon: undefined,
  aeur: undefined,
  agi: undefined,
  agrs: undefined,
  aion: undefined,
  algo: 'Algorand',
  amb: undefined,
  amp: undefined,
  ampl: undefined,
  ankr: undefined,
  ant: undefined,
  apex: undefined,
  appc: undefined,
  ardr: undefined,
  arg: undefined,
  ark: undefined,
  arn: undefined,
  arnx: undefined,
  ary: undefined,
  ast: undefined,
  atm: undefined,
  atom: undefined,
  audr: undefined,
  auto: undefined,
  avax: 'Avalanche',
  aywa: undefined,
  bab: undefined,
  bal: undefined,
  band: undefined,
  bat: undefined,
  bay: undefined,
  bcbc: undefined,
  bcc: undefined,
  bcd: undefined,
  bch: undefined,
  bcio: undefined,
  bcn: undefined,
  bco: undefined,
  bcpt: undefined,
  bdl: undefined,
  beam: undefined,
  bela: undefined,
  bix: undefined,
  blcn: undefined,
  blk: undefined,
  block: undefined,
  blz: undefined,
  bnb: 'Binance',
  bnt: undefined,
  bnty: undefined,
  booty: undefined,
  bos: undefined,
  bpt: undefined,
  bq: undefined,
  brd: undefined,
  bsd: undefined,
  bsv: undefined,
  btc: 'Bitcoin',
  btcd: undefined,
  btch: undefined,
  btcp: undefined,
  btcz: undefined,
  btdx: undefined,
  btg: undefined,
  btm: undefined,
  bts: undefined,
  btt: undefined,
  btx: undefined,
  burst: undefined,
  bze: undefined,
  call: undefined,
  cc: undefined,
  cdn: undefined,
  cdt: undefined,
  cenz: undefined,
  chain: undefined,
  chat: undefined,
  chips: undefined,
  chsb: undefined,
  cix: undefined,
  clam: undefined,
  cloak: undefined,
  cmm: undefined,
  cmt: undefined,
  cnd: undefined,
  cnx: undefined,
  cny: undefined,
  cob: undefined,
  colx: undefined,
  comp: undefined,
  coqui: undefined,
  cred: undefined,
  crpt: undefined,
  crv: undefined,
  crw: undefined,
  cs: undefined,
  ctr: undefined,
  ctxc: undefined,
  cvc: undefined,
  d: undefined,
  dai: undefined,
  dash: undefined,
  dat: undefined,
  data: undefined,
  dbc: undefined,
  dcn: undefined,
  dcr: undefined,
  deez: undefined,
  dent: undefined,
  dew: undefined,
  dgb: undefined,
  dgd: undefined,
  dlt: undefined,
  dnt: undefined,
  dock: undefined,
  doge: undefined,
  dot: undefined,
  drgn: undefined,
  drop: undefined,
  dta: undefined,
  dth: undefined,
  dtr: undefined,
  ebst: undefined,
  eca: undefined,
  edg: undefined,
  edo: undefined,
  edoge: undefined,
  ela: undefined,
  elec: undefined,
  elf: undefined,
  elix: undefined,
  ella: undefined,
  emb: undefined,
  emc: undefined,
  emc2: undefined,
  eng: undefined,
  enj: undefined,
  entrp: undefined,
  eon: undefined,
  eop: undefined,
  eos: undefined,
  eqli: undefined,
  equa: undefined,
  etc: undefined,
  eth: 'Ethereum',
  ethos: undefined,
  etn: undefined,
  etp: undefined,
  eur: undefined,
  evx: undefined,
  exmo: undefined,
  exp: undefined,
  fair: undefined,
  fct: undefined,
  fil: undefined,
  fjc: undefined,
  fldc: undefined,
  flo: undefined,
  flux: undefined,
  fsn: undefined,
  ftc: undefined,
  fuel: undefined,
  fun: undefined,
  game: undefined,
  gas: undefined,
  gbp: undefined,
  gbx: undefined,
  gbyte: undefined,
  generic: undefined,
  gin: undefined,
  glxt: undefined,
  gmr: undefined,
  gno: undefined,
  gnt: undefined,
  gold: undefined,
  grc: undefined,
  grin: undefined,
  grs: undefined,
  grt: undefined,
  gsc: undefined,
  gto: undefined,
  gup: undefined,
  gusd: undefined,
  gvt: undefined,
  gxs: undefined,
  gzr: undefined,
  hight: undefined,
  hns: undefined,
  hodl: undefined,
  hot: undefined,
  hpb: undefined,
  hsr: undefined,
  ht: undefined,
  html: undefined,
  huc: undefined,
  husd: undefined,
  hush: undefined,
  icn: undefined,
  icp: undefined,
  icx: undefined,
  ignis: undefined,
  ilk: undefined,
  ink: undefined,
  ins: undefined,
  ion: undefined,
  iop: undefined,
  iost: undefined,
  iotx: undefined,
  iq: undefined,
  itc: undefined,
  jnt: undefined,
  jpy: undefined,
  kcs: undefined,
  kin: undefined,
  klown: undefined,
  kmd: undefined,
  knc: undefined,
  krb: undefined,
  ksm: undefined,
  lbc: undefined,
  lend: undefined,
  leo: undefined,
  link: undefined,
  lkk: undefined,
  loom: undefined,
  lpt: undefined,
  lrc: undefined,
  lsk: undefined,
  ltc: undefined,
  lun: undefined,
  maid: undefined,
  mana: undefined,
  matic: undefined,
  max: undefined,
  mcap: undefined,
  mco: undefined,
  mda: undefined,
  mds: undefined,
  med: undefined,
  meetone: undefined,
  mft: undefined,
  miota: undefined,
  mith: undefined,
  mkr: undefined,
  mln: undefined,
  mnx: undefined,
  mnz: undefined,
  moac: undefined,
  mod: undefined,
  mona: undefined,
  msr: undefined,
  mth: undefined,
  mtl: undefined,
  music: undefined,
  mzc: undefined,
  nano: undefined,
  nas: undefined,
  nav: undefined,
  ncash: undefined,
  ndz: undefined,
  nebl: undefined,
  neo: undefined,
  neos: undefined,
  neu: undefined,
  nexo: undefined,
  ngc: undefined,
  nio: undefined,
  nkn: undefined,
  nlc2: undefined,
  nlg: undefined,
  nmc: undefined,
  nmr: undefined,
  npxs: undefined,
  ntbc: undefined,
  nuls: undefined,
  nxs: undefined,
  nxt: undefined,
  oax: undefined,
  ok: undefined,
  omg: undefined,
  omni: undefined,
  one: undefined,
  ong: undefined,
  ont: undefined,
  oot: undefined,
  ost: undefined,
  ox: undefined,
  oxt: undefined,
  part: undefined,
  pasc: undefined,
  pasl: undefined,
  pax: undefined,
  paxg: undefined,
  pay: undefined,
  payx: undefined,
  pink: undefined,
  pirl: undefined,
  pivx: undefined,
  plr: undefined,
  poa: undefined,
  poe: undefined,
  polis: undefined,
  poly: undefined,
  pot: undefined,
  powr: undefined,
  ppc: undefined,
  ppp: undefined,
  ppt: undefined,
  pre: undefined,
  prl: undefined,
  pungo: undefined,
  pura: undefined,
  qash: undefined,
  qiwi: undefined,
  qlc: undefined,
  qrl: undefined,
  qsp: undefined,
  qtum: undefined,
  r: undefined,
  rads: undefined,
  rap: undefined,
  rcn: undefined,
  rdd: undefined,
  rdn: undefined,
  ren: undefined,
  rep: undefined,
  repv2: undefined,
  req: undefined,
  rhoc: undefined,
  ric: undefined,
  rise: undefined,
  rlc: undefined,
  rpx: undefined,
  rub: undefined,
  rvn: undefined,
  ryo: undefined,
  safe: undefined,
  safemoon: undefined,
  sai: undefined,
  salt: undefined,
  san: undefined,
  sand: undefined,
  sbd: undefined,
  sberbank: undefined,
  sc: undefined,
  shift: undefined,
  sib: undefined,
  sin: undefined,
  skl: undefined,
  sky: undefined,
  slr: undefined,
  sls: undefined,
  smart: undefined,
  sngls: undefined,
  snm: undefined,
  snt: undefined,
  snx: undefined,
  soc: undefined,
  sol: undefined,
  spacehbit: undefined,
  spank: undefined,
  sphtx: undefined,
  srn: undefined,
  stak: undefined,
  start: undefined,
  steem: undefined,
  storj: undefined,
  storm: undefined,
  stox: undefined,
  stq: undefined,
  strat: undefined,
  stx: undefined,
  sub: undefined,
  sumo: undefined,
  sushi: undefined,
  sys: undefined,
  taas: undefined,
  tau: undefined,
  tbx: undefined,
  tel: undefined,
  ten: undefined,
  tern: undefined,
  tgch: undefined,
  theta: undefined,
  tix: undefined,
  tkn: undefined,
  tks: undefined,
  tnb: undefined,
  tnc: undefined,
  tnt: undefined,
  tomo: undefined,
  tpay: undefined,
  trig: undefined,
  trtl: undefined,
  trx: undefined,
  tusd: undefined,
  tzc: undefined,
  ubq: undefined,
  uma: undefined,
  uni: undefined,
  unity: undefined,
  usd: undefined,
  usdc: undefined,
  usdt: undefined,
  utk: undefined,
  veri: undefined,
  vet: undefined,
  via: undefined,
  vib: undefined,
  vibe: undefined,
  vivo: undefined,
  vrc: undefined,
  vrsc: undefined,
  vtc: undefined,
  vtho: undefined,
  wabi: undefined,
  wan: undefined,
  waves: undefined,
  wax: undefined,
  wbtc: undefined,
  wgr: undefined,
  wicc: undefined,
  wings: undefined,
  wpr: undefined,
  wtc: undefined,
  x: undefined,
  xas: undefined,
  xbc: undefined,
  xbp: undefined,
  xby: undefined,
  xcp: undefined,
  xdai: 'Gnosis',
  xdn: undefined,
  xem: undefined,
  xin: undefined,
  xlm: 'Stellar',
  xmcc: undefined,
  xmg: undefined,
  xmo: undefined,
  xmr: undefined,
  xmy: undefined,
  xp: undefined,
  xpa: undefined,
  xpm: undefined,
  xpr: undefined,
  xrp: undefined,
  xsg: undefined,
  xtz: undefined,
  xuc: undefined,
  xvc: undefined,
  xvg: undefined,
  xzc: undefined,
  yfi: undefined,
  yoyow: undefined,
  zcl: undefined,
  zec: undefined,
  zel: undefined,
  zen: undefined,
  zest: undefined,
  zil: undefined,
  zilla: undefined,
  zrx: undefined,
};

const tickerToWebsiteUrlMap: {[ticker: string]: string | undefined} = {
  $pac: undefined,
  '0xbtc': undefined,
  '2give': undefined,
  aave: undefined,
  abt: undefined,
  act: undefined,
  actn: undefined,
  ada: undefined,
  add: undefined,
  adx: undefined,
  ae: undefined,
  aeon: undefined,
  aeur: undefined,
  agi: undefined,
  agrs: undefined,
  aion: undefined,
  algo: 'https://www.algorand.com/',
  amb: undefined,
  amp: undefined,
  ampl: undefined,
  ankr: undefined,
  ant: undefined,
  apex: undefined,
  appc: undefined,
  ardr: undefined,
  arg: undefined,
  ark: undefined,
  arn: undefined,
  arnx: undefined,
  ary: undefined,
  ast: undefined,
  atm: undefined,
  atom: undefined,
  audr: undefined,
  auto: undefined,
  avax: 'https://www.avax.network/',
  aywa: undefined,
  bab: undefined,
  bal: undefined,
  band: undefined,
  bat: undefined,
  bay: undefined,
  bcbc: undefined,
  bcc: undefined,
  bcd: undefined,
  bch: undefined,
  bcio: undefined,
  bcn: undefined,
  bco: undefined,
  bcpt: undefined,
  bdl: undefined,
  beam: undefined,
  bela: undefined,
  bix: undefined,
  blcn: undefined,
  blk: undefined,
  block: undefined,
  blz: undefined,
  bnb: 'https://www.binance.com/',
  bnt: undefined,
  bnty: undefined,
  booty: undefined,
  bos: undefined,
  bpt: undefined,
  bq: undefined,
  brd: undefined,
  bsd: undefined,
  bsv: undefined,
  btc: 'https://bitcoin.org/',
  btcd: undefined,
  btch: undefined,
  btcp: undefined,
  btcz: undefined,
  btdx: undefined,
  btg: undefined,
  btm: undefined,
  bts: undefined,
  btt: undefined,
  btx: undefined,
  burst: undefined,
  bze: undefined,
  call: undefined,
  cc: undefined,
  cdn: undefined,
  cdt: undefined,
  cenz: undefined,
  chain: undefined,
  chat: undefined,
  chips: undefined,
  chsb: undefined,
  cix: undefined,
  clam: undefined,
  cloak: undefined,
  cmm: undefined,
  cmt: undefined,
  cnd: undefined,
  cnx: undefined,
  cny: undefined,
  cob: undefined,
  colx: undefined,
  comp: undefined,
  coqui: undefined,
  cred: undefined,
  crpt: undefined,
  crv: undefined,
  crw: undefined,
  cs: undefined,
  ctr: undefined,
  ctxc: undefined,
  cvc: undefined,
  d: undefined,
  dai: undefined,
  dash: undefined,
  dat: undefined,
  data: undefined,
  dbc: undefined,
  dcn: undefined,
  dcr: undefined,
  deez: undefined,
  dent: undefined,
  dew: undefined,
  dgb: undefined,
  dgd: undefined,
  dlt: undefined,
  dnt: undefined,
  dock: undefined,
  doge: undefined,
  dot: undefined,
  drgn: undefined,
  drop: undefined,
  dta: undefined,
  dth: undefined,
  dtr: undefined,
  ebst: undefined,
  eca: undefined,
  edg: undefined,
  edo: undefined,
  edoge: undefined,
  ela: undefined,
  elec: undefined,
  elf: undefined,
  elix: undefined,
  ella: undefined,
  emb: undefined,
  emc: undefined,
  emc2: undefined,
  eng: undefined,
  enj: undefined,
  entrp: undefined,
  eon: undefined,
  eop: undefined,
  eos: undefined,
  eqli: undefined,
  equa: undefined,
  etc: undefined,
  eth: 'https://ethereum.org/',
  ethos: undefined,
  etn: undefined,
  etp: undefined,
  eur: undefined,
  evx: undefined,
  exmo: undefined,
  exp: undefined,
  fair: undefined,
  fct: undefined,
  fil: undefined,
  fjc: undefined,
  fldc: undefined,
  flo: undefined,
  flux: undefined,
  fsn: undefined,
  ftc: undefined,
  fuel: undefined,
  fun: undefined,
  game: undefined,
  gas: undefined,
  gbp: undefined,
  gbx: undefined,
  gbyte: undefined,
  generic: undefined,
  gin: undefined,
  glxt: undefined,
  gmr: undefined,
  gno: undefined,
  gnt: undefined,
  gold: undefined,
  grc: undefined,
  grin: undefined,
  grs: undefined,
  grt: undefined,
  gsc: undefined,
  gto: undefined,
  gup: undefined,
  gusd: undefined,
  gvt: undefined,
  gxs: undefined,
  gzr: undefined,
  hight: undefined,
  hns: undefined,
  hodl: undefined,
  hot: undefined,
  hpb: undefined,
  hsr: undefined,
  ht: undefined,
  html: undefined,
  huc: undefined,
  husd: undefined,
  hush: undefined,
  icn: undefined,
  icp: undefined,
  icx: undefined,
  ignis: undefined,
  ilk: undefined,
  ink: undefined,
  ins: undefined,
  ion: undefined,
  iop: undefined,
  iost: undefined,
  iotx: undefined,
  iq: undefined,
  itc: undefined,
  jnt: undefined,
  jpy: undefined,
  kcs: undefined,
  kin: undefined,
  klown: undefined,
  kmd: undefined,
  knc: undefined,
  krb: undefined,
  ksm: undefined,
  lbc: undefined,
  lend: undefined,
  leo: undefined,
  link: undefined,
  lkk: undefined,
  loom: undefined,
  lpt: undefined,
  lrc: undefined,
  lsk: undefined,
  ltc: undefined,
  lun: undefined,
  maid: undefined,
  mana: undefined,
  matic: undefined,
  max: undefined,
  mcap: undefined,
  mco: undefined,
  mda: undefined,
  mds: undefined,
  med: undefined,
  meetone: undefined,
  mft: undefined,
  miota: undefined,
  mith: undefined,
  mkr: undefined,
  mln: undefined,
  mnx: undefined,
  mnz: undefined,
  moac: undefined,
  mod: undefined,
  mona: undefined,
  msr: undefined,
  mth: undefined,
  mtl: undefined,
  music: undefined,
  mzc: undefined,
  nano: undefined,
  nas: undefined,
  nav: undefined,
  ncash: undefined,
  ndz: undefined,
  nebl: undefined,
  neo: undefined,
  neos: undefined,
  neu: undefined,
  nexo: undefined,
  ngc: undefined,
  nio: undefined,
  nkn: undefined,
  nlc2: undefined,
  nlg: undefined,
  nmc: undefined,
  nmr: undefined,
  npxs: undefined,
  ntbc: undefined,
  nuls: undefined,
  nxs: undefined,
  nxt: undefined,
  oax: undefined,
  ok: undefined,
  omg: undefined,
  omni: undefined,
  one: undefined,
  ong: undefined,
  ont: undefined,
  oot: undefined,
  ost: undefined,
  ox: undefined,
  oxt: undefined,
  part: undefined,
  pasc: undefined,
  pasl: undefined,
  pax: undefined,
  paxg: undefined,
  pay: undefined,
  payx: undefined,
  pink: undefined,
  pirl: undefined,
  pivx: undefined,
  plr: undefined,
  poa: undefined,
  poe: undefined,
  polis: undefined,
  poly: undefined,
  pot: undefined,
  powr: undefined,
  ppc: undefined,
  ppp: undefined,
  ppt: undefined,
  pre: undefined,
  prl: undefined,
  pungo: undefined,
  pura: undefined,
  qash: undefined,
  qiwi: undefined,
  qlc: undefined,
  qrl: undefined,
  qsp: undefined,
  qtum: undefined,
  r: undefined,
  rads: undefined,
  rap: undefined,
  rcn: undefined,
  rdd: undefined,
  rdn: undefined,
  ren: undefined,
  rep: undefined,
  repv2: undefined,
  req: undefined,
  rhoc: undefined,
  ric: undefined,
  rise: undefined,
  rlc: undefined,
  rpx: undefined,
  rub: undefined,
  rvn: undefined,
  ryo: undefined,
  safe: undefined,
  safemoon: undefined,
  sai: undefined,
  salt: undefined,
  san: undefined,
  sand: undefined,
  sbd: undefined,
  sberbank: undefined,
  sc: undefined,
  shift: undefined,
  sib: undefined,
  sin: undefined,
  skl: undefined,
  sky: undefined,
  slr: undefined,
  sls: undefined,
  smart: undefined,
  sngls: undefined,
  snm: undefined,
  snt: undefined,
  snx: undefined,
  soc: undefined,
  sol: undefined,
  spacehbit: undefined,
  spank: undefined,
  sphtx: undefined,
  srn: undefined,
  stak: undefined,
  start: undefined,
  steem: undefined,
  storj: undefined,
  storm: undefined,
  stox: undefined,
  stq: undefined,
  strat: undefined,
  stx: undefined,
  sub: undefined,
  sumo: undefined,
  sushi: undefined,
  sys: undefined,
  taas: undefined,
  tau: undefined,
  tbx: undefined,
  tel: undefined,
  ten: undefined,
  tern: undefined,
  tgch: undefined,
  theta: undefined,
  tix: undefined,
  tkn: undefined,
  tks: undefined,
  tnb: undefined,
  tnc: undefined,
  tnt: undefined,
  tomo: undefined,
  tpay: undefined,
  trig: undefined,
  trtl: undefined,
  trx: undefined,
  tusd: undefined,
  tzc: undefined,
  ubq: undefined,
  uma: undefined,
  uni: undefined,
  unity: undefined,
  usd: undefined,
  usdc: undefined,
  usdt: undefined,
  utk: undefined,
  veri: undefined,
  vet: undefined,
  via: undefined,
  vib: undefined,
  vibe: undefined,
  vivo: undefined,
  vrc: undefined,
  vrsc: undefined,
  vtc: undefined,
  vtho: undefined,
  wabi: undefined,
  wan: undefined,
  waves: undefined,
  wax: undefined,
  wbtc: undefined,
  wgr: undefined,
  wicc: undefined,
  wings: undefined,
  wpr: undefined,
  wtc: undefined,
  x: undefined,
  xas: undefined,
  xbc: undefined,
  xbp: undefined,
  xby: undefined,
  xcp: undefined,
  xdai: 'https://www.gnosischain.com/',
  xdn: undefined,
  xem: undefined,
  xin: undefined,
  xlm: 'https://www.stellar.org/',
  xmcc: undefined,
  xmg: undefined,
  xmo: undefined,
  xmr: undefined,
  xmy: undefined,
  xp: undefined,
  xpa: undefined,
  xpm: undefined,
  xpr: undefined,
  xrp: undefined,
  xsg: undefined,
  xtz: undefined,
  xuc: undefined,
  xvc: undefined,
  xvg: undefined,
  xzc: undefined,
  yfi: undefined,
  yoyow: undefined,
  zcl: undefined,
  zec: undefined,
  zel: undefined,
  zen: undefined,
  zest: undefined,
  zil: undefined,
  zilla: undefined,
  zrx: undefined,
};

const tickerToName = (ticker: string) => {
  return tickerToNameMap[ticker];
};

const tickerToWebsiteUrl = (ticker: string) => {
  return tickerToWebsiteUrlMap[ticker];
};

export {tickerToName, tickerToWebsiteUrl};
