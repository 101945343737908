import React from 'react';
import './App.module.scss';
import {Outlet} from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import classes from './App.module.scss';

function App() {
  return (
    <div className={classes.App}>
      <Layout>
        <Outlet />
      </Layout>
    </div>
  );
}

export default App;
