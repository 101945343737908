import React, {FC} from 'react';

import classes from './Layout.module.scss';
import NavBar from './NavBar/NavBar';
import {Container} from 'react-bootstrap';
import Footer from './Footer/Footer';

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({children}) => {
  return (
    <div className={classes.Layout}>
      <NavBar />
      <Container className={classes.ChildrenContainer}>{children}</Container>
      <Container>
        <Footer />
      </Container>
    </div>
  );
};

export default Layout;
