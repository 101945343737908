import React, {FC, useEffect, useState} from 'react';

import buildingsSvg from '../../assets/svgs/buildings.svg';
import chartSvg from '../../assets/svgs/chart-line.svg';
import dollarSignSvg from '../../assets/svgs/dollar-sign.svg';
import classes from './AggregateBoxes.module.scss';
import AggregateBox from './AggregateBox/AggregateBox';
import {Col, Row} from 'react-bootstrap';
import Utils from '../../utils/utils';

interface AggregateBoxesProps {
  companies: any[];
}

const AggregateBoxes: FC<AggregateBoxesProps> = (props) => {
  const [totalProperties, setTotalProperties] = useState(0);
  const [totalTvl, setTotalTvl] = useState(0);
  const [avgMinBuy, setAvgMinBuy] = useState(0);

  useEffect(() => {
    const totalProperties = props.companies.reduce((total, company) => {
      return company.propertiesCount !== undefined
        ? total + company.propertiesCount
        : total;
    }, 0);
    setTotalProperties(totalProperties);
  }, [props.companies]);

  useEffect(() => {
    const totalTvl = props.companies.reduce((total, company) => {
      return company.totalValueLocked !== undefined
        ? total + company.totalValueLocked
        : total;
    }, 0);
    setTotalTvl(totalTvl);
  }, [props.companies]);

  useEffect(() => {
    const totalMinBuy = props.companies.reduce((total, company) => {
      return company.minimumBuy !== undefined
        ? total + company.minimumBuy
        : total;
    }, 0);

    const totalCompaniesCounted = props.companies.reduce((total, company) => {
      return company.minimumBuy !== undefined ? total + 1 : total;
    }, 0);

    setAvgMinBuy(totalMinBuy / totalCompaniesCounted);
  }, [props.companies]);

  return (
    <Row className={classes.AggregateBoxes}>
      <Col>
        <AggregateBox
          title={'Total Properties'}
          value={totalProperties}
          icon={buildingsSvg}
        />
      </Col>
      <Col>
        <AggregateBox
          title={'Total TVL'}
          value={Utils.moneyFormat(totalTvl, 0)}
          icon={chartSvg}
        />
      </Col>
      <Col>
        <AggregateBox
          title={'Average Min Buy'}
          value={Utils.moneyFormat(avgMinBuy, 0)}
          icon={dollarSignSvg}
        />
      </Col>
    </Row>
  );
};

export default AggregateBoxes;
