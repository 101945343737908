import React, {FC, useEffect, useState} from 'react';

import classes from './Home.module.scss';
import CompaniesList from '../../Components/CompaniesList/CompaniesList';
import AggregateBoxes from '../../Components/AggregateBoxes/AggregateBoxes';
import {Col, Row} from 'react-bootstrap';
import axios from 'axios';
import NewsFeed from '../../Components/NewsFeed/NewsFeed';

const Home: FC = (props) => {
  const [companies, setCompanies] = useState<any>([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await axios
        .get(`https://api.propertycoins.io/api/v1/companies`)
        .then((res) => {
          setCompanies(res.data.data);
        });
    };

    fetchCompanies().catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <div className={classes.Home}>
      <Row>
        <Col>
          <AggregateBoxes companies={companies} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CompaniesList companies={companies} />
        </Col>
      </Row>
      <Row>
        <Col>
          <NewsFeed />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
