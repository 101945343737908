import {configureStore, combineReducers} from '@reduxjs/toolkit';


export const store = configureStore({
    reducer: combineReducers({
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [],
                ignoredActionPaths: [],
                ignoredPaths: [],
            },
        }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
