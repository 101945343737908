import React, {FC, useEffect, useState} from 'react';
import classes from './CryptoChainIcon.module.scss';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
  tickerToName,
  tickerToWebsiteUrl,
} from '../../../utils/cryptoTickerToName';

interface CryptoChainIconProps {
  identifier: string;
  size: number;
}

const CryptoChainIcon: FC<CryptoChainIconProps> = (props) => {
  const [image, setImage] = useState<string>('');
  const [name, setName] = useState<string | undefined>(undefined);
  const [websiteUrl, setWebsiteUrl] = useState<string | undefined>(undefined);

  const handleClicked = () => {
    websiteUrl && window.open(websiteUrl, '_blank');
  };

  useEffect(() => {
    import(`../../../assets/images/chain-icons/${props.identifier}.png`).then(
      (image) => {
        setImage(image.default);
      },
    );
  }, [props.identifier]);

  useEffect(() => {
    setName(tickerToName(props.identifier));
  }, [props.identifier]);

  useEffect(() => {
    setWebsiteUrl(tickerToWebsiteUrl(props.identifier));
  }, [props.identifier]);

  return (
    <OverlayTrigger
      placement={'top'}
      overlay={name ? <Tooltip>{name}</Tooltip> : <></>}
    >
      <img
        className={classes.CryptoChainIcon}
        src={image}
        height={props.size}
        onClick={handleClicked}
      />
    </OverlayTrigger>
  );
};

export default CryptoChainIcon;
